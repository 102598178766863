import WimsicalError from 'components/atoms/WimsicalError/WimsicalError';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { QueryErrorModel } from 'models/ErrorModel';
import { useGetBirstSSOTokenQuery } from 'redux/services/basil/basilApi';
import { formatErrorMessage } from 'utils/formatErrorMessage';

export const Reports = (): JSX.Element => {
  const { data, isLoading, isFetching, isError, error } = useGetBirstSSOTokenQuery();

  if (isLoading || isFetching) return <LoaderWithMessage loadingMessage="Loading Reports" />;

  if (isError) {
    const err = error as QueryErrorModel;

    return <WimsicalError title={formatErrorMessage(typeof err.status === 'number' ? err.status : 400)} subTitle={err?.data?.errorMessage ? err.data.errorMessage : ''} statusCode={err.status} />;
  }

  return (
    <div>
      <h1>Reports</h1>
      <iframe
        width={'100%'}
        height={800}
        title="birst"
        src={`https://login.bws.birst.com/SSO.aspx?BirstSSOToken=${data?.accessToken}
      &birst.module=newDashboards&birst.exportZoom=2&&amp;birst.embedded=true
      &birst.hideDashboardNavigation=false&birst.hideHeader=true`}
      />
    </div>
  );
};
