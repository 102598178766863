import { About } from 'pages/About';
import { EditProfilePage } from 'pages/EditProfile';
import { Reports } from 'pages/Reports';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Reports />} />
      <Route path="/about" element={<About />} />
      <Route path="/profile/" element={<EditProfilePage />} />
    </Routes>
  );
};
